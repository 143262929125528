import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import Person from '@mui/icons-material/Person';
import useGenericGetExecutives from '@fingo/lib/hooks/useGenericGetExecutive';

const ExecutiveFilter = ({ filters, updateFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = useCallback((event) => {
    if (filters.executiveFilter) updateFilters('executiveFilter', null);
    else if (!filters.executiveFilter) setAnchorEl(event.currentTarget);
  }, [filters.executiveFilter]);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const { executives: commercialExecutives } = useGenericGetExecutives({ executive_Active: true });
  const executivesComponent = useMemo(() => (
    commercialExecutives.map((executive) => (
      <MenuItem
        key={executive.node.id}
        onClick={() => {
          updateFilters('executiveFilter', { id: executive.node.graphqlId });
          closeMenu();
        }}
      >
        <ListItemText primary={executive.node.completeName} />
      </MenuItem>
    ))
  ), [commercialExecutives]);
  return (
    <>
      <IconButton
        onClick={openMenu}
        sx={{
          backgroundColor: !filters.executiveFilter ? 'white' : 'primary.A200',
          borderRadius: '10px',
          height: '35px',
          width: '35px',
        }}
      >
        <Person />
      </IconButton>
      <Menu
        keepMounted
        sx={{ display: 'inline', zIndex: 2000 }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        id="documents-company-menu"
      >
        <MenuItem
          key="no-executive-id"
          onClick={() => {
            updateFilters('executiveFilter', { id: null });
            closeMenu();
          }}
        >
          <ListItemText
            primaryTypographyProps={{ style: { fontStyle: 'italic' } }}
            primary="Sin Ejecutivo Asignado"
          />
        </MenuItem>
        {executivesComponent}
      </Menu>
    </>
  );
};

ExecutiveFilter.propTypes = {
  filters: PropTypes.shape({
    executiveFilter: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
};
export default ExecutiveFilter;
