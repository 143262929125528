import React, { useMemo } from 'react';
import Check from '@mui/icons-material/Check';
import DoneAll from '@mui/icons-material/DoneAll';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import SmsFailed from '@mui/icons-material/SmsFailed';
import AccessTime from '@mui/icons-material/AccessTime';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const MessageStatus = ({ lastEvent, ...props }) => {
  const eventStatus = lastEvent?.whatsappLogEvent?.status ?? 'NOSTATUS';
  const eventStatusIcon = useMemo(() => {
    if (eventStatus === 'READ') return <Tooltip title="Leído"><DoneAll color="info.A400" {...props} /></Tooltip>;
    if (eventStatus === 'SENT') return <Tooltip title="Envíado"><Check {...props} /></Tooltip>;
    if (eventStatus === 'DELIVERED') return <Tooltip title="Entregado"><DoneAll {...props} /></Tooltip>;
    if (eventStatus === 'SENDING') return <Tooltip title="Enviando"><AccessTime {...props} /></Tooltip>;
    if (eventStatus === 'QUEUED') return <Tooltip title="En cola"><HourglassBottom color="warning" {...props} /></Tooltip>;
    return (<Tooltip title="Hubo algún error :("><SmsFailed color="error" /></Tooltip>);
  }, [eventStatus]);
  return eventStatusIcon;
};

MessageStatus.propTypes = {
  lastEvent: PropTypes.shape({
    id: PropTypes.string,
    whatsappLogEvent: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
};

export default MessageStatus;
