import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import {
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stack,
  Typography,
} from '@mui/material';
import { whatsappConversationTime } from '@fingo/lib/helpers';
import Circle from '@mui/icons-material/Circle';
import MediaBodyItem from './MediaBodyItem';
import RelatedUserInformation from '../ChatView/RelatedUserInformation';

const ConversationItem = ({
  conversation,
  selected,
  setSelectedConversation,
}) => {
  const notSeenAlertTag = useMemo(() => {
    if (!conversation.lastMessage.inbox) return <></>;
    if (conversation.lastMessageSeen) return <></>;
    return <Circle sx={{ color: '#25d366', height: '15px', width: '15px' }} />;
  }, [conversation]);

  return (
    <ListItemButton
      sx={{ maxWidth: 400 }}
      key={`conversation-${conversation.id}`}
      selected={selected}
      onClick={() => setSelectedConversation({
        hashId: conversation.conversationHash,
        twilioId: conversation.twilioSender.id,
      })}
    >
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText
        sx={{ width: '50%' }}
        primaryTypographyProps={{
          color: 'common.black',
          variant: 'h5',
          noWrap: true,
        }}
        secondaryTypographyProps={{
          color: 'common.black',
          noWrap: true,
        }}
        primary={(
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>
              {!conversation.relatedUser
                ? `${conversation.fromPhoneNumber}`
                : `${conversation.relatedUser.firstName} ${conversation.relatedUser.lastName}`}
            </Typography>
            <RelatedUserInformation conversation={conversation} />
          </Stack>
        )}
        secondary={<MediaBodyItem conversation={conversation} />}
      />
      <ListItemText
        secondaryTypographyProps={{
          noWrap: true,
          align: 'right',
        }}
        primaryTypographyProps={{
          align: 'right',
        }}
        primary={notSeenAlertTag}
        secondary={whatsappConversationTime(
          dayjs(conversation.lastMessageDatetime),
        )}
      />
    </ListItemButton>
  );
};

ConversationItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  setSelectedConversation: PropTypes.func.isRequired,
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastMessageDatetime: PropTypes.string.isRequired,
    conversationHash: PropTypes.string.isRequired,
    lastMessageSeen: PropTypes.bool.isRequired,
    fromPhoneNumber: PropTypes.string.isRequired,
    relatedUser: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    lastMessage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      inbox: PropTypes.bool.isRequired,
      body: PropTypes.string.isRequired,
      lastEvent: PropTypes.shape({
        id: PropTypes.string,
        whatsappLogEvent: PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string,
        }),
      }).isRequired,
      whatsapplogfileSet: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          contentType: PropTypes.string,
        }),
      ),
    }).isRequired,
    twilioSender: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ConversationItem;
