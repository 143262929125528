import React from 'react';
import PropTypes from 'prop-types';
import { timeSinceGivenDate, formatDateTime } from '@fingo/lib/helpers';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import dayjs from '@fingo/lib/config/dayjs';
import MessageStatus from '../Messages/MessageStatus';
import FileWhatsappMessage from '../Messages/FileWhatsappMessage';

const MessageContent = ({ message, inbox }) => {
  const { createdAt } = message;
  return (
    <Box
      display="flex"
      alignItems={inbox ? 'flex-start' : 'flex-end'}
      justifyContent={inbox ? 'flex-start' : 'flex-end'}
      p={0.5}
    >
      <Card
        sx={{
          background: inbox ? 'primary.A200' : '#25d366',
          padding: '10px',
          borderRadius: '15px',
          maxWidth: '50%',
          display: 'inline-flex',
        }}
      >
        <Stack spacing={0.2}>
          <FileWhatsappMessage
            whatsapplogfileSet={message.whatsapplogfileSet}
          />
          <Typography fontSize="13px" align="left">
            {message.body}
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            {!inbox && (
              <MessageStatus
                lastEvent={message.lastEvent}
                sx={{ height: '10px' }}
              />
            )}
            <Tooltip title={formatDateTime(dayjs(createdAt))}>
              <Typography fontSize="13px">
                {timeSinceGivenDate(createdAt)}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

MessageContent.propTypes = {
  inbox: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    createdAt: PropTypes.string,
    preOffer: PropTypes.shape({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
      }),
    }),
    lastEvent: PropTypes.shape({
      id: PropTypes.string,
      whatsappLogEvent: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    whatsapplogfileSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

export default MessageContent;
