import React from 'react';
import * as Yup from 'yup';
import {
  CompanyProfileCell,
  CustomerDocumentsCell,
  ProgressBarCell,
} from '@fingo/lib/components/cells';
import { AvatarExecutiveAssignedCell } from '@fingo/lib/components/dataGridCells';
import { formatMoney } from '@fingo/lib/helpers';
import dayjs from '@fingo/lib/config/dayjs';

export const INVOICE_RECOMMENDATION_COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: ({ row }) => row.invoice.folio,
  },
  {
    field: 'receiverName',
    headerName: 'Receptor',
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    valueGetter: ({ row }) => row.invoice.receiver.name,
  },
  {
    field: 'receiverNationalIdentifier',
    headerName: 'RUT Receptor',
    sortable: false,
    disableColumnMenu: true,
    width: 105,
    valueGetter: ({ row }) => row.invoice.receiver.displayNationalIdentifier,
  },
  {
    field: 'dateIssued',
    type: 'date',
    headerName: 'Fecha emisión',
    sortable: false,
    disableColumnMenu: true,
    width: 105,
    valueGetter: ({ row }) => row.invoice.dateIssued,
    valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    valueGetter: ({ row }) => row.invoice.amountWithIva.amount,
    valueFormatter: ({ value }) => `$${formatMoney(value)}`,
  },
  {
    field: 'preoffer_MonthlyRate',
    headerName: 'Tasa',
    sortable: false,
    disableColumnMenu: true,
    width: 70,
    valueGetter: ({ row }) => row.invoice.preoffer.monthlyRate,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'preoffer_DefaultRate',
    headerName: 'Mora',
    sortable: false,
    disableColumnMenu: true,
    width: 70,
    valueGetter: ({ row }) => row.invoice.preoffer.defaultRate,
    valueFormatter: ({ value }) => `${value}%`,
  },
];

export const COMPANY_COLUMNS = [
  {
    field: 'name',
    headerName: 'Cliente',
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
  },
  {
    field: 'documents',
    headerName: 'Documentos',
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ row }) => {
      const { company } = row;
      if (!company) return null;

      return <CustomerDocumentsCell masterEntity={row} wrap />;
    },
  },
  {
    field: 'creditLine_CreditUsed',
    headerName: 'Línea usada',
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ row }) => (
      <ProgressBarCell
        value={row.creditLine?.creditUsed || 0}
        total={row.creditLine?.currentLimit?.newLimit?.amount || 0}
        startTooltipTitle="Línea usada"
        endTooltipTitle="Línea total"
        isMoney
        currency={row.creditLine?.currency.isoCode}
      />
    ),
  },
  {
    field: 'company_ExecutiveAssigned__firstName',
    headerName: 'Ejecutivo',
    headerAlign: 'center',
    disableColumnMenu: true,
    sortable: true,
    width: 90,
    renderCell: ({ row }) => (
      <AvatarExecutiveAssignedCell
        companyId={row.id}
        user={row.company?.executiveAssigned}
        assignationType="executive"
      />
    ),
  },
];

export const HEADER_TITLE = 'Lista de clientes recomendados';

export const HEADERS = [
  'name',
  'documents',
  'creditLine_CreditUsed',
  'company_ExecutiveAssigned__firstName',
];

export const CONFIRM_INVOICE_RECOMMENDATIONS_INITIAL_VALUES = {
  date: dayjs(),
};

export const CONFIRM_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA = Yup.object({
  date: Yup.date().required('Este campo es obligatorio'),
});

export const DECLINE_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA = Yup.object({
  failureReason: Yup.string().required('Este campo es obligatorio'),
  clientInterest: Yup.string().required('Este campo es obligatorio'),
  details: Yup.string(),
});

export const DECLINE_INVOICE_RECOMMENDATIONS_INITIAL_VALUES = {
  failureReason: '',
  clientInterest: '',
  company: null,
  rate: '',
  details: '',
};

export const DECLINE_INVOICE_RECOMMENDATIONS_FAILURE_REASONS = [
  {
    label: 'Cliente opera con otra institución',
    value: 'CUSTOMER_WITH_COMPETITORS',
  },
  {
    label: 'Cliente recibirá el pago en los próximos días',
    value: 'WILl_RECEIVE_PAYMENT_SOON',
  },
  {
    label: 'Cliente no necesita financiamiento',
    value: 'DOES_NOT_REQUIRE_FINANCING',
  },
  {
    label: 'Fingo no toma esta factura',
    value: 'INVOICE_DO_NOT_QUIALIFY',
  },
];

export const DECLINE_INVOICE_RECOMMENDATIONS_CLIENT_INTEREST_OPTIONS = [
  {
    label: 'Cliente interesado en recibir simulación en el futuro',
    value: 'INTERESTED_IN_SIMULATION',
  },
  {
    label: 'Cliente no interesado',
    value: 'NOT_INTERESTED',
  },
  {
    label: 'Cliente molesto',
    value: 'ANNOYED',
  },
];
