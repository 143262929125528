import React, { useMemo } from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import useSimulationAmounts from '@fingo/lib/hooks/useSimulationAmounts';
import { CREATE_FACTORING_SIMULATION_FILE } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useTextFieldInput } from '@fingo/lib/hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EditableText from './EditableText';

const SimulationSummary = ({ invoices }) => {
  const history = useHistory();
  const [commission, setCommission, reset] = useTextFieldInput();
  const newInvoices = invoices.map((inv) => ({
    ...inv,
    days: dayjs(inv.dateToPay).diff(dayjs(inv.dateIssued), 'days'),
  }));
  const summaryInfo = useSimulationAmounts(newInvoices);
  const company = useMemo(() => {
    if (!invoices.length) return null;
    return invoices[0].company.masterEntity;
  });
  const [createSimulationFile, { loading }] = useMutation(
    CREATE_FACTORING_SIMULATION_FILE,
    {
      variables: {
        invoiceIds: newInvoices.map((inv) => inv.id),
        invoiceDays: newInvoices.map((inv) => inv.days),
        simulationType: 'PREOFFER',
        commission:
          commission
          || summaryInfo.find(({ label }) => label === 'Comisión').value,
        checkCommission: false,
      },
      onCompleted: (data) => {
        window.open(data.createSimulationFile.url);
        history.replace('/app/comercial/simulations/list');
      },
    },
  );
  return (
    <Stack alignItems="center" width="100%" spacing={2}>
      <Typography color="primary" variant="h5">
        Simulación {company?.name}
      </Typography>
      {summaryInfo.map(({ label, value, format, editable }) => (
        <Stack key={label} width="100%" direction="row" alignItems="center">
          <Box width="70%">
            <Typography>{label}</Typography>
          </Box>
          <Box width="30%">
            {editable ? (
              <EditableText
                initialValue={commission || value}
                setValue={setCommission}
                formatedValue={format(commission || value)}
                reset={reset}
              />
            ) : (
              <Typography>{format ? format(value) : value}</Typography>
            )}
          </Box>
        </Stack>
      ))}
      <Box pt={4}>
        <LoadingButton
          onClick={createSimulationFile}
          loading={loading}
          variant="contained"
          color="primary"
          size="small"
          disabled={!invoices.length}
        >
          Crear simulación y descargar
        </LoadingButton>
      </Box>
    </Stack>
  );
};

SimulationSummary.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SimulationSummary;
