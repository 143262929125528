import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import Campaign from '@mui/icons-material/Campaign';
import useCreateMarketingCampaign from '../../../marketing-campaign/hooks/useCreateMarketingCampaign';

const MarketingCampaignFilter = ({ filters, updateFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = useCallback((event) => {
    if (filters.marketingFilter) updateFilters('marketingFilter', null);
    else if (!filters.marketingFilter) setAnchorEl(event.currentTarget);
  }, [filters.marketingFilter]);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [, { mails }] = useCreateMarketingCampaign(
    { sendingDate: '', mailId: '', emailNameSearch: '', contacts: null, countryId: 1 },
  );

  return (
    <>
      <IconButton
        sx={{
          backgroundColor: !filters.marketingFilter ? 'white' : 'primary.A200',
          borderRadius: '10px',
          height: '35px',
          width: '35px',
        }}
        onClick={openMenu}
      >
        <Campaign />
      </IconButton>
      <Menu
        keepMounted
        sx={{ display: 'inline', zIndex: 2000 }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        id="documents-company-menu"
      >
        <MenuItem
          key="no-marketing-campaign-id"
          onClick={() => {
            updateFilters('marketingFilter', { id: null });
            closeMenu();
          }}
        >
          <ListItemText
            primaryTypographyProps={{ style: { fontStyle: 'italic' } }}
            primary="Sin Campaña Masiva"
          />
        </MenuItem>
        {mails.map((campaign) => (
          <MenuItem
            key={`marketing-campaign-id-${campaign.id}`}
            onClick={() => {
              updateFilters('marketingFilter', { id: campaign.graphqlId });
              closeMenu();
            }}
          >
            <ListItemText primary={campaign.emailName} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MarketingCampaignFilter.propTypes = {
  filters: PropTypes.shape({
    marketingFilter: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
};
export default MarketingCampaignFilter;
